const tableHeadArr = [
    {
        id: "fullName",
        textAlign: "center",
        scope: "row",
        disablePadding: false,
        label: "שם מלא",
        show: true,
    },
    {
        id: "userId",
        textAlign: "center",
        disablePadding: false,
        label: "תעודת זהות",
        show: true,
    },
    {
        id: "phoneNum",
        textAlign: "center",
        disablePadding: false,
        label: "מספר טלפון",
        show: true,
    },
    {
        id: "email",
        textAlign: "center",
        disablePadding: false,
        label: "אימייל",
        show: true,
    },
    {
        id: "gender",
        textAlign: "center",
        disablePadding: false,
        label: "מגדר",
        show: true,
    },
    {
        id: "department",
        textAlign: "center",
        disablePadding: false,
        label: "מחלקה",
        show: true,
    },
    {
        id: "role",
        textAlign: "center",
        disablePadding: false,
        label: "תפקיד",
        show: true,
    },
    {
        id: "userRoleGroupDesc",
        textAlign: "center",
        disablePadding: false,
        label: "אופי תפקיד",
        show: false,
    },
    {
        id: "userType",
        textAlign: "center",
        disablePadding: false,
        label: "דרג",
        show: false,
    },
    {
        id: "managerName",
        textAlign: "center",
        disablePadding: false,
        label: "מנהל ישיר",
        show: true,
    },
    {
        id: "managerEmail",
        textAlign: "center",
        disablePadding: false,
        label: "מייל מנהל ישיר",
        show: true,
    },
    {
        id: "is_Active",
        textAlign: "center",
        disablePadding: false,
        label: "פעיל?",
        show: true,
    },
    {
        id: "is_Admin",
        textAlign: "center",
        disablePadding: false,
        label: "אדמין?",
        show: true,
    },
];

export default tableHeadArr;